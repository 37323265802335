import React, { Suspense } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import HeroSection from "../components/Home/HeroSection"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import ReviewSection from "../components/Home/Testimonial"
// import CategorySection from "../components/Home/CategorySection"
// import WelcomeSection from "../components/Home/WelcomeSection"

// const HeroSection = React.lazy(() => import("../components/Home/HeroSection"));
const CategorySection = React.lazy(() => import("../components/Home/CategorySection"));
const WelcomeSection = React.lazy(() => import("../components/Home/WelcomeSection"));


const IndexPage = (props) => {
  const homePageData = props?.data?.homeJson
  return (

    <Layout>
      <Suspense fallback={'loading...'}>
        <HeroSection data={props.data.homeJson.slider_images} />
        <div className="max-w-6xl mx-auto bg-white border-t-7 md:p-8 p-4 pt-11 drop-shadow-xl">
          <section className="py-16 md:py-16 text-center">
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900">{homePageData?.hero_section?.heading}</h2>
            <p className="text-lg md:text-xl mx-auto my-10 text-gray-700 mt-4 text-center">
              {homePageData?.hero_section?.subtext}
            </p>
            <div className="mt-6">
              <Link to={homePageData?.hero_section?.button_link} className="px-6 py-3 bg-cyan-800 text-white font-bold rounded-none shadow-lg hover:bg-blue-700">
                {homePageData?.hero_section?.button_text}
              </Link>
            </div>
          </section>

          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-10 text-center">Our Vinyl Fence Styles</h2>
          <CategorySection />


          {/* 🔥 Section 2: Why Choose Vinyl Fencing */}
          <section className="py-10 md:py-14 flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 text-left">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900"> {homePageData?.why_choose_section?.heading}</h2>
              <ul className="mt-4 space-y-3 text-lg text-gray-700">
                {homePageData?.why_choose_section?.benefits?.map((benefit, index) => (
                  <li key={index}> {benefit?.title}</li>
                ))}
                {/* <li>✅ <strong>100% Maintenance-Free</strong> – No painting, staining, or repairs</li>
                <li>✅ <strong>Weather-Resistant</strong> – Designed for Toronto’s harsh winters</li>
                <li>✅ <strong>Eco-Friendly</strong> – Made from recyclable materials</li>
                <li>✅ <strong>Cost-Effective</strong> – Lasts decades with no upkeep</li> */}
              </ul>
            </div>
            <div className="md:w-1/2 mt-6 md:mt-0">
              <GatsbyImage
                image={getImage(homePageData?.why_choose_section?.image)}
                alt={homePageData?.why_choose_section?.image}
                className="rounded-lg shadow-lg w-full"
              // loading="eager"
              />
              {/* <StaticImage
                src="../images/fence-benefits.webp"
                alt="Vinyl Fence Benefits"
                className="rounded-lg shadow-lg w-full"
                placeholder="blurred"
              /> */}
            </div>
          </section>

          {/* 🔥 Section 3: Our Vinyl Fence Styles */}
          <section className="py-10 md:py-14  text-center">
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900"> {homePageData?.fence_styles_section?.heading} </h2>
            <p className="text-lg md:text-xl mx-auto my-10 text-gray-700 mt-4 text-center">
              {homePageData?.fence_styles_section?.subtext}
              {/* Choose from a variety of elegant designs to match your home. Whether you need privacy fencing,
              picket fences, or modern designs, we’ve got you covered. */}
            </p>
            <div className="mt-6 grid md:grid-cols-3 gap-6">
              {/* Privacy Fence */}
              {homePageData?.fence_styles_section?.styles?.map((style, index) => (
                <div className="p-5 bg-white shadow-lg rounded-lg" key={index} >
                  <GatsbyImage
                    image={getImage(style?.image)}
                    alt={style?.name}
                    className="w-full rounded-lg"
                    placeholder="blurred"
                  />
                  <h3 className="text-xl font-bold mt-3">{style?.name}</h3>
                  <p className="text-gray-700">{style?.description}</p>
                </div>
              ))}
              {/* <div className="p-5 bg-white shadow-lg rounded-lg">
                <StaticImage
                  src="../images/privacy-fence.jpg"
                  alt="Privacy Fence"
                  className="w-full rounded-lg"
                  placeholder="blurred"
                />
                <h3 className="text-xl font-bold mt-3">Privacy Fence</h3>
                <p className="text-gray-700">Ideal for complete backyard privacy & security.</p>
              </div> */}
              {/* Picket Fence */}
              {/* <div className="p-5 bg-white shadow-lg rounded-lg">
                <StaticImage
                  src="../images/picket-fence.jpeg"
                  alt="Picket Fence"
                  className="w-full rounded-lg"
                  placeholder="blurred"
                />
                <h3 className="text-xl font-bold mt-3">Picket Fence</h3>
                <p className="text-gray-700">Classic & stylish fencing for front yards.</p>
              </div> */}
              {/* Modern Fence */}
              {/* <div className="p-5 bg-white shadow-lg rounded-lg">
                <StaticImage
                  src="../images/modern-fence.jpg"
                  alt="Modern Fence"
                  className="w-full rounded-lg"
                  placeholder="blurred"
                />
                <h3 className="text-xl font-bold mt-3">Modern Fence</h3>
                <p className="text-gray-700">Sleek & contemporary vinyl fencing designs.</p>
              </div> */}
            </div>
            <div className="mt-12">
              <Link to={homePageData?.fence_styles_section?.button_link} className="px-6 py-3 bg-cyan-800 text-white font-bold rounded-none shadow-lg hover:bg-blue-700">
                {homePageData?.fence_styles_section?.button_text}
              </Link>
            </div>
          </section>
          <WelcomeSection data={props.data.homeJson.content} />
          <ReviewSection />
        </div>
      </Suspense>

    </Layout>
  )
}

export const Head = (props) => <Seo title={props.data.homeJson.meta.seoTitle} description={props.data.homeJson.meta.seoDescription} page="home" path={props.location.pathname} />


export const pageQuery = graphql`
  query HomePageQuery {
    homeJson {
      title
      meta {
        seoTitle
        seoDescription
      }
      hero_section {
      heading
      subtext
      button_text
      button_link
    }
    why_choose_section {
      heading
      benefits {
        title
      }
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920, quality: 90)
        }
      }
    }
    fence_styles_section {
      heading
      button_text
      button_link
      subtext
      styles {
        name
        description
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 1920, quality: 90)
          }
        }
      }
    }
      content
      slider_images {
        alt
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 1920, quality: 90)
          }
        }
      }
    }
  }
`
export default IndexPage
