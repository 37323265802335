import React from 'react'

const ReviewSection = () => {
    const reviews = [
        {
            name: "Sarah Thompson",
            review:
                "We had a vinyl fence installed in our Toronto backyard last summer and couldn't be happier. It’s maintenance-free and looks fantastic all year round.",
            rating: 5,
        },
        {
            name: "Daniel Ruiz",
            review:
                "Top-notch vinyl fence installation in Toronto. The team was punctual, professional, and the fence really boosted our property value.",
            rating: 5,
        },
        {
            name: "Meghan Li",
            review:
                "If you're looking for quality vinyl fencing in Toronto, this is the company to call. Great design options and super clean work.",
            rating: 4,
        },
        {
            name: "Owen Carter",
            review:
                "We upgraded to a vinyl privacy fence in our Toronto home and it’s a game-changer. Zero maintenance and looks brand new even after heavy snow.",
            rating: 5,
        },
        {
            name: "Priya Desai",
            review:
                "I did a lot of research before choosing a vinyl fence installer in Toronto and I’m glad I found these guys. They exceeded expectations.",
            rating: 5,
        },
        {
            name: "Jason McMillan",
            review:
                "Reliable and efficient vinyl fencing installation across Toronto. The crew was friendly and they completed the job ahead of schedule.",
            rating: 4,
        },
        // {
        //     name: "Emily Novak",
        //     review:
        //         "The best decision we made for our home was choosing vinyl fencing. It’s perfect for our Toronto climate, and the team handled everything smoothly.",
        //     rating: 5,
        // },
    ];
    return (
        <section className="bg-white py-10 px-6">
            <h2 className="text-3xl font-bold text-gray-800 text-center mb-2">
                Vinyl Fence Installation Reviews – Trusted by Toronto Homeowners
            </h2>
            <p className="text-gray-600 mb-8 text-center max-w-3xl mx-auto">
                Discover what our customers say about our expert vinyl fence installation
                services in Toronto. From backyard privacy to weather-resistant fencing,
                these reviews highlight the quality, durability, and satisfaction our
                clients experience every day.
            </p>
            <div className="grid gap-6 sm:grid-cols-3">
                {reviews.map((r, i) => (
                    <div key={i} className="border rounded-xl p-4 shadow-sm bg-gray-50">
                        <p className="text-lg font-semibold text-gray-900">{r.name}</p>
                        <p className="text-sm text-gray-600 mt-1">{r.review}</p>
                        <div className="text-yellow-500 mt-2">
                            {"★".repeat(r.rating)}{"☆".repeat(5 - r.rating)}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );

};

export default ReviewSection;